import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import UtahSymphonyImage from '../../assets/images/publication-banners/utah-symphony.jpg';

export const query = graphql`
  query SymphonyQuery {
    allUtahsymphonyCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
        Image2
      }
    }
  }
`;

export default function UtahSymphony({ data }) {
  const symphonyNodes = data.allUtahsymphonyCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/symphony/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Utah Symphony - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Utah Symphony’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Utah Symphony</h1>
            </header>
            <span className="image main">
              <img src={UtahSymphonyImage} alt="" />
            </span>
            <p className="box">
              Advertisers will be exposed to this unique demographic and partake
              in the vital presence that the Utah Symphony has in the
              surrounding community. Advertisers will also gain lasting
              exposure, as the majority of patrons take their playbills home and
              refer to the contents for a long time thereafter. Take advantage
              of this opportunity to have your business be part of one of Utah’s
              most beloved art organizations and contact us today to reserve
              your advertising space.
            </p>
            <ul className="actions">
              <li>
                <a href="https://UtahSymphony.org/" className="button special">
                  <i className="icon fa-globe"> </i>Utah Symphony Website
                </a>
              </li>
            </ul>
            <h2>
              Utah Symphony <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            {/* *********************
          *********************
          BREAK
          *********************
          ********************* */}
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the array, and maps/creates a new array from every element in the called array. */}
              {symphonyNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image2}
                  />
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <br />
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {node.FR}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                        <tr>
                          <td>Print Quantity: {node.Quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
